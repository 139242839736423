<template>
  <v-container fluid class="hidden-sm-and-down logo">
    <div class="endereço_container">
      <img src="@/assets/location_icon.svg" alt="Icone de Localização">
      <a href="https://maps.app.goo.gl/owj7QGqjYqNsoT3W6" target="_blank">
        <p class="endereço">R. Duque de Caxias, 95 - Centro | Uberlândia - MG, 38400-142</p>
      </a>
    </div>
    <div class="telefone_containter">
      <img src="@/assets/telefone_icon.svg" alt="Icone de Telefone">
      <p class="telefone">(34) 3236 7277</p>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "LogoBar",
  data() {
    return {
      logo: require("@/assets/logo2.png")
    };
  }
};
</script>

<style lang="sass">
  .logo
    background-color: #1E88E5
    display: flex
    align-items: center
    justify-content: space-between
    padding: 9px 140px

  .endereço_container, .telefone_containter
    display: flex
    align-items: center

  .endereço, .telefone
    color: white
    margin-bottom: 0px !important
    margin-left: 8px
    font-size: 14px
    font-weight: 300


</style>
